import { Collapse, CollapseProps, theme } from 'antd';
import styles from './index.module.less';
import { CSSProperties, useState } from 'react';
import { CommonBackContent } from '@/components/Layouts/CommonBackContent';
import { useTranslation } from 'next-i18next';
// import FavoriteInfluencerAlert from '../InfluencersContent/FavoriteInfluencerAlert';

const FAQContent = ({ isFromHome = false }: { isFromHome?: boolean }) => {
  const { t } = useTranslation('common');
  const { token } = theme.useToken();

  const en_dataList = [
    {
      label: 'How does Planbowl build Emergency Preparedness Guides?',
      children: [
        `Planbowl uses AI to analyze the geographical location, household size, and floor level you provide. It then creates a customized Emergency Preparedness Guide, including necessary supply lists tailored to your specific situation.`,
      ],
    },
    {
      label: 'Is my personal information safe when I enter my location and household size?',
      children: [
        `Yes, your privacy is our top priority. We use advanced encryption methods to protect your data and ensure it is not shared with third parties.`,
      ],
    },
    {
      label: 'Why do I need to provide my geographical location?',
      children: [
        `The geographical location helps our AI generate a more accurate Emergency Preparedness Guide by considering local risks and resources specific to your area.`,
      ],
    },
    {
      label: 'What information is included in the Emergency Preparedness Guide?',
      children: [
        `The guide includes a list of necessary supplies, contact information for emergency services, and other essential information tailored to your location and household needs.`,
      ],
    },
    {
      label: `What types of Emergency Preparedness Guides does Planbowl offer?`,
      children: [
        `Planbowl offers two types of Emergency Preparedness Guides: All-In-One Disaster Prep and Single-Threat Readiness. The All-In-One Disaster Prep covers a wide range of disaster scenarios with a detailed supply list, while the Single-Threat Readiness focuses on specific disasters, providing more detailed analysis and specific supply lists, including a pet care module.`,
      ],
    },
    {
      label: `Can I edit the supply list in the guides?`,
      children: [
        `In the All-In-One Disaster Prep, the supply list is fully editable to suit your specific needs. The Single-Threat Readiness provides a fixed supply list tailored to the selected disaster and does not support editing.`,
      ],
    },
    {
      label: `How does the pet care module in the Single-Threat Readiness work?`,
      children: [
        `The pet care module in the Single-Threat Readiness provides specific emergency preparedness information and a supply list tailored for pets. This ensures that you have everything you need to keep your pets safe during a disaster.`,
      ],
    },
    {
      label: `Can I trust the accuracy of the AI-built guides?`,
      children: [
        `Yes, absolutely! Our AI system is designed using the latest data and best practices in disaster preparedness. It also leverages authoritative knowledge bases to provide highly accurate and reliable information about potential disasters or emergencies in your area.`,
      ],
    },
    {
      label: 'Is there a cost for the Emergency Preparedness Guide?',
      children: [
        `Yes, there is a one-time fee for our customized guides. This fee ensures you have permanent access and can make updates as needed, with no additional costs.`,
      ],
    },
    // {
    //   label: 'Can I edit the Emergency Preparedness Guide after purchase?',
    //   children: [
    //     `Yes, of course! After purchasing the guide, you can permanently edit your guide.`,
    //   ],
    // },
    {
      label: 'Can I save the Emergency Preparedness Guide?',
      children: [`Yes, you can print it out as a paper guide or save it as a digital version.`],
    },
    {
      label: 'How long will it take to receive my Emergency Preparedness Guide?',
      children: [
        `You will receive your customized Emergency Preparedness Guide in as fast as 1 minute after completing your purchase.`,
      ],
    },
    {
      label: 'What customization options are available for the guide?',
      children: [
        `Planbowl's guides offer customizable features that allow you to edit and modify the report, ensuring it meets your specific requirements and preferences.`,
      ],
    },
  ];

  const dataList = () => {
    return en_dataList;
  };

  const getItems: (panelStyle: CSSProperties) => CollapseProps['items'] = (panelStyle) => {
    return dataList().map((item, index) => {
      return {
        label: t(item.label),
        children: (
          <div className={styles.infoContainer}>
            {item.children.map((item, index) => {
              if (typeof item === 'string') {
                return <p key={index} dangerouslySetInnerHTML={{ __html: t(item) }} />;
              } else {
                return <p key={index}>{item}</p>;
              }
            })}
          </div>
        ),
        key: `${index}`,
        style: panelStyle,
      };
    });
  };

  const panelStyle: React.CSSProperties = {
    marginBottom: 24,
    background: `var(--ground-rgb-1)`,
    borderRadius: token.borderRadiusLG,
    border: 'none',
    color: 'red',
    boxShadow: `0 0 2px #D9D9D9`,
    // border: 1px solid #D9D9D9;
  };

  return (
    <CommonBackContent style={{ padding: 0 }}>
      <div className={styles.content}>
        <div className={styles.topContainer}>
          {!isFromHome && (
            <h1>
              <span>{t('FREQUENTLY ASKED QUESTIONS')}</span>
            </h1>
          )}
          {isFromHome && (
            <h2>
              <span>{t('Frequently asked questions')}</span>
            </h2>
          )}
        </div>

        <Collapse
          className={styles.collapseContent}
          bordered={false}
          items={getItems(panelStyle)}
          defaultActiveKey={undefined}
          // onChange={onChange}
        />
      </div>

      {/* <FavoriteInfluencerAlert
        open={open}
        handleClose={() => setOpen(false)} /> */}
    </CommonBackContent>
  );
};

export default FAQContent;
